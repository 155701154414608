import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import axios from 'axios';
import { Loading } from 'components/Loading/Loading';
import { sendRefreshTokenRequest } from 'helpers/auth/auth-client';
import { postServerLogOut, setAuthDataState, setupAuthInterceptor } from 'helpers/auth/auth-interceptor';
import { AppRouter } from './navigation';
import { OpenIdCallback } from './openid/OpenIdCallback';
import { backendUri } from './openid/openid-settings';

import './index.scss';

export const appUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

axios.defaults.baseURL = appUrl;
setupAuthInterceptor(axios, async (authData) => await sendRefreshTokenRequest(authData.refresh_token));

const rootElement = document.getElementById('root');
if (rootElement === null) throw new Error('Could not find root element.');
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <OpenIdCallback
      signInRedirectHandler={(user) => {
        setAuthDataState({
          access_token: user.access_token,
          refresh_token: user.refresh_token!,
        });
        window.history.pushState(null, '', backendUri);
      }}
      signOutRedirectHandler={() => postServerLogOut()}
      loading={<Loading loading={true} />}
      error={(e) => <div>{`Error: ${e.error}`}</div>}
    >
      <AppRouter />
    </OpenIdCallback>
  </StrictMode>,
);
