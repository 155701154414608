import { DeviceFirmwareGeneration, FirmwareContentType, GenerationsInfoMap } from 'api/firmware-api';
import { Result, splitResults } from 'helpers/resultUtils';
import { useMemo } from 'react';
import { UploadFirmwareFileModel } from './useUploadFirmwareFilesForm';

type ParsedFirmwareFile = {
  content: File;
  contentType: FirmwareContentType;
  generation: DeviceFirmwareGeneration;
  version?: string;
};

export const transformProcessedFiles = (parsedFiles: ParsedFirmwareFile[]): UploadFirmwareFileModel[] =>
  parsedFiles.map((file) => ({
    content: { file: file.content, type: file.contentType },
    generation: file.generation,
    version: file.version ?? '',
  }));

export const useFirmwareFilesProcessor = () => useMemo(() => tryProcessFirmwareFiles, []);

const tryProcessFirmwareFiles = async (files: File[]) => splitResults(await Promise.all(tryGetFirmwareFiles(files)));

const tryGetFirmwareFiles = (files: File[]) => files.map(tryProcessFile);

const tryProcessFile = async (file: File): Promise<Result<ParsedFirmwareFile, string>> => {
  try {
    return { hasError: false, result: await getFirmwareFileAndGeneration(file) };
  } catch (error: any) {
    return { hasError: true, error: `An error occured when processing ${file.name}: ${error}` };
  }
};

const getFirmwareFileAndGeneration = async (file: File): Promise<ParsedFirmwareFile> => {
  const firmware = parseFirmwareFile(file);
  if (!firmware) throw 'File does not match any of the known firmware update files.';

  return {
    content: firmware.file,
    version: firmware.version,
    generation: firmware.generation.firmwareGeneration,
    contentType: firmware.content.type,
  };
};

const parseFirmwareFile = (file: File) => {
  const info = getFirmwareContentInfo(file);
  return info && { ...info, file };
};

const getFirmwareContentInfo = (file: File) =>
  GenerationsInfoMap.map((generation) => {
    const content = generation.content
      .map((content) => ({ match: file.name.match(content.name), content }))
      .find((content) => content.match);

    const version = content?.match?.groups?.version;
    return content && { generation: generation, content: content.content, version };
  }).find((generation) => generation);
