import {
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@mui/material';
import { DeviceWhiteListDto, DistributionType, TargetType } from 'api/firmware-api';
import ArrowDownIcon from 'assets/icons/dropdown-arrow.svg';
import { AppDateTimePicker } from 'components/AppDateTimePicker/AppDateTimePicker';
import { CheckboxGroup } from 'components/CheckBoxGroup/CheckboxGroup';
import { Field } from 'components/Field/Field';
import { MultiSelect } from 'components/MultiSelect/MultiSelect';
import { DropDown } from 'components/Select/DropDown/DropDown';
import React, { useState, useMemo } from 'react';
import {
  UseFormRegister,
  Control,
  UseFormGetValues,
  UseFormSetValue,
  Merge,
  FieldError,
  FieldErrorsImpl,
  Controller,
} from 'react-hook-form';
import {
  FormReleases,
  ReleaseCardModel,
  ContriesSelector,
  getDefaultContriesSelector,
  StagesSelector,
  getDefaultStagesSelector,
  DistributionWay,
  changeDistributionWays,
  getDistributionWayOptions,
  getStagesDefaultValueByStagesSelector,
  mapWhiteListsToDropDownOption,
} from './utils';
import { useIsoCodes } from 'helpers/useIsoCodes';

import styles from './ReleaseCard.module.scss';

type ReleaseProps = {
  stages: string[];
  countries: string[];
  whiteLists: DeviceWhiteListDto[];
  index: number;
  register: UseFormRegister<FormReleases>;
  control: Control<FormReleases, any>;
  getValues: UseFormGetValues<FormReleases>;
  setValue: UseFormSetValue<FormReleases>;
  errors?: Merge<FieldError, FieldErrorsImpl<ReleaseCardModel>>;
  onDuplicate: (release: ReleaseCardModel) => void;
  onDelete: (index: number) => void;
  openUploadWhiteListModal: () => void;
};

export const ReleaseCard: React.FC<ReleaseProps> = ({
  stages,
  countries,
  whiteLists,
  index,
  register,
  control,
  getValues,
  setValue,
  errors,
  onDuplicate,
  onDelete,
  openUploadWhiteListModal,
}) => {
  const { getCountryName } = useIsoCodes();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [contriesSelector, setContriesSelector] = useState<ContriesSelector>(
    getDefaultContriesSelector(getValues(`releases.${index}`)),
  );
  const [stagesSelector, setStagesSelector] = useState<StagesSelector>(
    getDefaultStagesSelector(getValues(`releases.${index}`)),
  );
  const pCloudDistributionWayIndex = useMemo(() => {
    const selectedDistributionWays = getValues(`releases.${index}.distributionWays`);

    if (!selectedDistributionWays) {
      return -1;
    }

    return selectedDistributionWays.findIndex((dW) => dW.type === DistributionType.PrismaCloud);
  }, [getValues(`releases.${index}.distributionWays`)]);

  const handleOnChangeTargetType = (selectedType: TargetType) => {
    setValue(`releases.${index}.whiteListId`, '');
    setValue(`releases.${index}.distributionWays`, []);
    if (selectedType === TargetType.ByWhiteList) {
      setContriesSelector(null);
      setValue(`releases.${index}.countries`, undefined);
      setValue(`releases.${index}.partialReleasePercentage`, 100);
    }
    if (selectedType === TargetType.ByCountry) {
      setContriesSelector('Particular');
      setValue(`releases.${index}.countries`, []);
      setValue(`releases.${index}.partialReleasePercentage`, undefined);
    }
  };

  const handleOnChangeContriesSelector = (selectedSelector: ContriesSelector) => {
    setContriesSelector(selectedSelector);
    if (selectedSelector === 'All') {
      setValue(`releases.${index}.countries`, undefined);
    }
    if (selectedSelector === 'Particular') {
      setValue(`releases.${index}.countries`, []);
    }
  };

  const handleOnChangeStagesSelector = (stagesSelector: StagesSelector) => {
    setStagesSelector(stagesSelector);

    if (stagesSelector === null || pCloudDistributionWayIndex === -1) {
      return;
    }

    const stagesDefaultValue = getStagesDefaultValueByStagesSelector(stagesSelector);
    setValue(`releases.${index}.distributionWays.${pCloudDistributionWayIndex}.stages`, stagesDefaultValue);
  };

  const handleOnChangeDistributionWay = (
    value: string,
    checked: boolean,
    onChange: (dW: DistributionWay[]) => void,
    currentDW: DistributionWay[],
  ) => {
    const checkedDistributionType = value as DistributionType;
    const changedDW = changeDistributionWays(checkedDistributionType, checked, currentDW);
    if (checkedDistributionType === DistributionType.PrismaCloud) {
      if (checked) {
        setStagesSelector('Particular');
        // Length - 1 because each new distribution way added to the end of array.
        const pCloudDW = changedDW[changedDW.length - 1];
        pCloudDW.stages = getStagesDefaultValueByStagesSelector(stagesSelector);
      } else {
        setStagesSelector(null);
      }
    }
    onChange(changedDW);
  };

  const handleOnDelete = () => onDelete(index);

  const handleOnDuplicate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onDuplicate(getValues(`releases.${index}`));
    e.stopPropagation();
  };

  const startDate = register(`releases.${index}.startDate`, { required: { value: true, message: 'Required' } });
  const countriesField = register(`releases.${index}.countries`, {
    required: { value: contriesSelector === 'Particular', message: 'Required' },
  });
  const whiteListId = register(`releases.${index}.whiteListId`, {
    required: { value: getValues(`releases.${index}.type`) === TargetType.ByWhiteList, message: 'Required' },
  });
  const partialReleasePercentage = register(`releases.${index}.partialReleasePercentage`, {
    required: { value: getValues(`releases.${index}.type`) === TargetType.ByWhiteList, message: 'Required' },
    min: { value: 1, message: 'Percentage is lower 1' },
    max: { value: 100, message: 'Percentage limit is exceeded' },
  });
  const distributionWays = register(`releases.${index}.distributionWays`, {
    required: { value: true, message: 'Set at least one distribute type' },
  });

  return (
    <Accordion expanded={isExpanded}>
      <AccordionSummary
        sx={{ marginLeft: '6px' }}
        expandIcon={<ArrowDownIcon height={20} width={20} />}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className={styles.releaseAccordionSummary}>
          <div className={styles.releaseHeader}>Release {index + 1}</div>
          <div>
            <Button
              sx={{
                color: '#6e6e6e',
                [':hover']: {
                  backgroundColor: 'transparent',
                  color: '#6e6e6e',
                },
              }}
              variant="text"
              key={'delete'}
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              sx={{
                color: '#80b000',
                [':hover']: {
                  backgroundColor: 'transparent',
                  color: '#80b000',
                },
              }}
              className={styles.duplicateTextButton}
              variant="text"
              key={'duplicate'}
              onClick={(e) => handleOnDuplicate(e)}
            >
              Duplicate
            </Button>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.fieldsArea}>
          <Field title={'Release date'}>
            <Controller
              control={control}
              name={startDate.name}
              render={({ field }) => (
                <AppDateTimePicker
                  type="dateOnly"
                  error={!!errors?.startDate}
                  helperText={errors?.startDate?.message}
                  {...field}
                />
              )}
            />
          </Field>
          <Field title={'Distribution type'}>
            <Controller
              control={control}
              name={register(`releases.${index}.type`).name}
              render={({ field: { value, onChange } }) => (
                <RadioGroup
                  row
                  value={value}
                  onChange={(event) => {
                    handleOnChangeTargetType(event.target.value as TargetType);
                    onChange(event);
                  }}
                >
                  <FormControlLabel value={TargetType.ByCountry} control={<Radio />} label={'Countries'} />
                  <FormControlLabel value={TargetType.ByWhiteList} control={<Radio />} label={'White list'} />
                </RadioGroup>
              )}
            />
          </Field>
          {getValues(`releases.${index}.type`) === TargetType.ByCountry && (
            <Field title={'Countries'} fieldClassName={styles.complexField}>
              <RadioGroup
                row
                value={contriesSelector}
                onChange={(event) => handleOnChangeContriesSelector(event.target.value as ContriesSelector)}
              >
                <FormControlLabel value={'Particular'} control={<Radio />} label={'Particular'} />
                <FormControlLabel value={'All'} control={<Radio />} label={'All'} />
              </RadioGroup>
              {contriesSelector === 'Particular' && (
                <Controller
                  control={control}
                  name={countriesField.name}
                  render={({ field }) => (
                    <MultiSelect
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={'None are selected'}
                      options={countries}
                      error={!!errors?.countries}
                      helperText={errors?.countries?.message}
                      labelStrategy={getCountryName}
                    />
                  )}
                />
              )}
            </Field>
          )}
          {getValues(`releases.${index}.type`) === TargetType.ByWhiteList && (
            <>
              <Field title={'White list'} fieldClassName={styles.complexField}>
                <Controller
                  control={control}
                  name={whiteListId.name}
                  render={({ field: { value, onChange } }) => (
                    <DropDown
                      placeholder={'Select white list file'}
                      options={mapWhiteListsToDropDownOption(whiteLists)}
                      value={value ? value : null}
                      onChange={onChange}
                      error={!!errors?.whiteListId}
                      helperText={errors?.whiteListId?.message}
                    />
                  )}
                />
                <a className={styles.link} onClick={openUploadWhiteListModal}>
                  Upload white list
                </a>
              </Field>
              <Field title={'Share'} fieldClassName={styles.shareInput}>
                <Controller
                  control={control}
                  name={partialReleasePercentage.name}
                  render={({ field }) => (
                    <TextField
                      sx={{ width: '100%' }}
                      type="number"
                      {...field}
                      error={!!errors?.partialReleasePercentage}
                      helperText={errors?.partialReleasePercentage?.message}
                    />
                  )}
                />
                <span className={styles.percentageSymbol}>%</span>
              </Field>
            </>
          )}
          <Field title={'Distribute by'}>
            <Controller
              control={control}
              name={distributionWays.name}
              render={({ field: { onChange } }) => (
                <CheckboxGroup
                  error={!!errors?.distributionWays}
                  helperText={errors?.distributionWays?.message}
                  options={getDistributionWayOptions(
                    getValues(`releases.${index}.type`),
                    getValues(`releases.${index}.distributionWays`),
                  )}
                  onChange={(selectedDistributionType, checked) =>
                    handleOnChangeDistributionWay(
                      selectedDistributionType,
                      checked,
                      onChange,
                      getValues(`releases.${index}.distributionWays`),
                    )
                  }
                />
              )}
            />
          </Field>
          {pCloudDistributionWayIndex !== -1 && (
            <Field title={'pCLOUD stages'} fieldClassName={styles.complexField}>
              <RadioGroup
                row
                value={stagesSelector}
                onChange={(event) => handleOnChangeStagesSelector(event.target.value as StagesSelector)}
              >
                <FormControlLabel value={'Particular'} control={<Radio />} label={'Particular'} />
                <FormControlLabel value={'All'} control={<Radio />} label={'All'} />
              </RadioGroup>
              {stagesSelector === 'Particular' && (
                <Controller
                  control={control}
                  name={
                    register(`releases.${index}.distributionWays.${pCloudDistributionWayIndex}.stages`, {
                      required: { value: true, message: 'Required' },
                    }).name
                  }
                  render={({ field }) => (
                    <MultiSelect
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={'Select stages'}
                      options={stages}
                      error={
                        !!(errors?.distributionWays && errors?.distributionWays[pCloudDistributionWayIndex]?.stages)
                      }
                      helperText={
                        errors?.distributionWays
                          ? errors?.distributionWays[pCloudDistributionWayIndex]?.stages?.message
                          : undefined
                      }
                    />
                  )}
                />
              )}
            </Field>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
