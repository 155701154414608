import {
  WhiteListDto,
  WhiteListSortingAttribute,
  WhiteListSortingAttributes,
  WhiteListsQueryParameters,
} from 'api/white-list-api';
import React, { createContext, useMemo, useState } from 'react';
import { TopPanel } from '../TopPanel/TopPanel';
import { Loading } from 'components/Loading/Loading';
import { AppTable } from 'components/AppTable/AppTable';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useInfiniteScrolling } from 'helpers/InfiniteScrolling';
import {
  useInfiniteDevicesWhiteListsPageQuery,
  useInvalidateDevicesWhiteListsPageQuery,
  useWhiteListCreateMutation,
  useWhiteListDeleteMutation,
  useWhiteListUpdateMutation,
} from 'helpers/react-query/devices-white-lists-query-hooks';
import { SortingType } from 'api/common';
import { createEnumParam, NumberParam, StringParam, useQueryParams, withDefault } from 'react-router-url-params';
import { useDevicesWhiteListsPageColumns } from './columns';
import { UploadWhiteListModal } from '../Forms/UploadWhiteListModal/UploadWhiteListModal';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { AppModal } from 'components/AppModal/AppModal';
import { useSortingControl } from 'components/SortableHeader/SortableHeader';

import styles from './DeviceWhiteListsPage.module.scss';

const defaultPageParams: WhiteListsQueryParameters = {
  pageSize: 16,
  sortingType: SortingType.Descending,
  sortingAttribute: 'lastChangedAt',
};

export type WhiteListSelectionType = 'deleting' | 'editing' | 'creating';
export type SelectedWhiteList = {
  whiteList?: WhiteListDto;
  selectionType: WhiteListSelectionType;
};

type SelectedWhiteListContext = {
  selectedWhiteList: SelectedWhiteList | null;
  setSelectedWhiteList: React.Dispatch<React.SetStateAction<SelectedWhiteList | null>>;
};

export const SelectedWhiteListContext = createContext<SelectedWhiteListContext>({
  selectedWhiteList: null,
  setSelectedWhiteList: () => undefined,
});

export const DeviceWhiteListsPage: React.FC = () => {
  const [selectedWhiteList, setSelectedWhiteList] = useState<SelectedWhiteList | null>(null);
  const isSelectedFileEditing = useMemo(
    () => selectedWhiteList?.selectionType === 'editing',
    [selectedWhiteList?.selectionType],
  );
  const isSelectedFileCreating = useMemo(
    () => selectedWhiteList?.selectionType === 'creating',
    [selectedWhiteList?.selectionType],
  );
  const [{ name, selectedSortingType, selectedSortingAttribute }, setQueryParams] = useQueryParams({
    name: withDefault(StringParam, ''),
    selectedSortingType: withDefault(NumberParam, defaultPageParams.sortingType),
    selectedSortingAttribute: withDefault(
      createEnumParam([...WhiteListSortingAttributes]),
      defaultPageParams.sortingAttribute,
    ),
  });

  const query = useInfiniteDevicesWhiteListsPageQuery({
    ...defaultPageParams,
    name: name,
    sortingType: selectedSortingType,
    sortingAttribute: selectedSortingAttribute,
  });
  const { ref, flatData } = useInfiniteScrolling(query, ({ items: sharedFiles }) => sharedFiles);
  const { isFetching } = query;

  const invalidate = useInvalidateDevicesWhiteListsPageQuery();
  const onSuccess = () => {
    setSelectedWhiteList(null);
    invalidate();
  };
  const createWhiteListMutation = useWhiteListCreateMutation(onSuccess);
  const updateWhiteListMutation = useWhiteListUpdateMutation(onSuccess);
  const deleteWhiteListMutation = useWhiteListDeleteMutation(onSuccess);

  const handleSearchChange = (newValue: string) => {
    setQueryParams({ name: newValue });
  };

  const handleOnCreateButtonClick = () => {
    setSelectedWhiteList({ selectionType: 'creating' });
  };

  const handleOnConfirmDeleting = async () => {
    if (selectedWhiteList?.whiteList) {
      await deleteWhiteListMutation.mutateAsync(selectedWhiteList.whiteList.id);
    }
  };

  const getSortingControl = (sortingAttribute: WhiteListSortingAttribute) =>
    useSortingControl(
      selectedSortingType,
      (sortingType: SortingType) =>
        setQueryParams({ selectedSortingType: sortingType, selectedSortingAttribute: sortingAttribute }),
      sortingAttribute === selectedSortingAttribute,
    );
  const columns = useDevicesWhiteListsPageColumns({
    nameSortingControl: getSortingControl('name'),
    lastChangedAtSortingControl: getSortingControl('lastChangedAt'),
  });
  const table = useReactTable<WhiteListDto>({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    data: flatData,
    columns: columns,
  });

  return (
    <SelectedWhiteListContext.Provider
      value={{
        selectedWhiteList: selectedWhiteList,
        setSelectedWhiteList: setSelectedWhiteList,
      }}
    >
      <Loading loading={isFetching} />
      <TopPanel
        searchValue={name}
        onSearchInputStop={handleSearchChange}
        onCreateButtonClick={handleOnCreateButtonClick}
        requestDelay={800}
        placeholder={'Search by name'}
      />
      <AppTable table={table} estimateRowSize={() => 60} classNames={{ bodyCell: styles.cell }} bottomRef={ref} />
      {selectedWhiteList && (isSelectedFileEditing || isSelectedFileCreating) && (
        <UploadWhiteListModal
          open={true}
          title={isSelectedFileEditing ? 'Edit white list' : 'Upload white list'}
          defaultValue={selectedWhiteList.whiteList}
          onClose={() => setSelectedWhiteList(null)}
          onSubmit={isSelectedFileEditing ? updateWhiteListMutation.mutateAsync : createWhiteListMutation.mutateAsync}
          isBusy={isSelectedFileEditing ? updateWhiteListMutation.isLoading : createWhiteListMutation.isLoading}
        />
      )}
      {selectedWhiteList?.selectionType === 'deleting' && (
        <AppModal
          onClose={() => setSelectedWhiteList(null)}
          open={true}
          children={
            <ConfirmationModal
              title={`Delete white list “${selectedWhiteList.whiteList?.name}”?`}
              description="It will be permanently removed from the white lists."
              onCancel={() => setSelectedWhiteList(null)}
              confirmButtonLabel="Delete white list"
              isLoading={deleteWhiteListMutation.isLoading}
              onConfirm={handleOnConfirmDeleting}
            />
          }
        />
      )}
    </SelectedWhiteListContext.Provider>
  );
};
