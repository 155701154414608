import React from 'react';

import { DistributionType, FirmwareTargetDto, TargetType } from 'api/firmware-api';
import { addWhiteListFileExtension } from 'features/FirmwarePage/WhiteLists/utils';
import { formatAsDate } from 'helpers/dateUtils';
import { useIsoCodes } from 'helpers/useIsoCodes';
import { getDistributionTypeLabel } from '../../Forms/CreateReleases/utils';

import styles from './FirmwareTarget.module.scss';

export type FirmwareReleaseProps = {
  target: FirmwareTargetDto;
};

export const FirmwareTarget: React.FC<FirmwareReleaseProps> = ({ target }) => {
  const { getCountryName } = useIsoCodes();

  return (
    <div>
      <div>
        <strong>
          {`${formatAsDate(target.startDate)}- ${
            target.type === TargetType.ByCountry
              ? target.countryTarget?.countries
                ? target.countryTarget?.countries.map((country) => getCountryName(country)).join(', ')
                : 'All countries'
              : target.whiteListTarget?.whiteList.name &&
                addWhiteListFileExtension(target.whiteListTarget?.whiteList.name)
          }`}
        </strong>
        {target.type === TargetType.ByWhiteList && (
          <span className={styles.percentage}>{target.whiteListTarget?.partialReleasePercentage}%</span>
        )}
      </div>
      <div>
        {target.distributionWays
          .map((dW) =>
            dW.type === DistributionType.PrismaCloud
              ? getDistributionTypeLabel(dW.type) +
                (dW.byPrismaCloud?.stages?.length ? ` (${dW.byPrismaCloud?.stages.join(', ')})` : '(All stages)')
              : getDistributionTypeLabel(dW.type),
          )
          .join(', ')}
      </div>
    </div>
  );
};
