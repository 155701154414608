import { useMutation } from '@tanstack/react-query';
import React, { useContext } from 'react';

import { FirmwareApi } from 'api/firmware-api';
import { AppModal } from 'components/AppModal/AppModal';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { useInvalidateFirmwarePageQuery } from 'helpers/react-query/firmware-query-hooks';
import { SelectedFirmwareFileContext } from '../DeviceFirmwareListsPage/DeviceFirmwareListsPage';

export const DeleteFirmwareModal = () => {
  const { selectedFile, setSelectedFile } = useContext(SelectedFirmwareFileContext);
  const onClose = () => setSelectedFile(null);

  const invalidate = useInvalidateFirmwarePageQuery();
  const deleteFileMutation = useMutation((fileId: string) => FirmwareApi.delete(fileId), {
    onSuccess: () => {
      invalidate();
      onClose();
    },
  });

  return (
    <AppModal
      open={selectedFile?.selectionType === 'deleting'}
      onClose={onClose}
      preventClose={deleteFileMutation.isLoading}
    >
      {selectedFile && (
        <ConfirmationModal
          title="Delete firmware?"
          description="This action cannot be undone."
          onCancel={onClose}
          isLoading={deleteFileMutation.isLoading}
          confirmButtonLabel="Delete"
          onConfirm={async () => {
            await deleteFileMutation.mutateAsync(selectedFile.file.id);
            onClose();
          }}
        />
      )}
    </AppModal>
  );
};
