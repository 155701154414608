import React, { useContext, useState } from 'react';
import { AppModal } from 'components/AppModal/AppModal';
import { AppForm } from 'components/AppForm/AppForm';
import { FirmwareApi, FirmwareTargetDto, TargetType } from 'api/firmware-api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from '@mui/material';
import {
  FormReleases,
  mapFirmwareTargetDtosToReleaseCardModels,
  mapReleaseCardModelsToFirmwareTargetDtos,
  ReleaseCardModel,
} from './utils';
import {
  SelectedFirmwareFile,
  SelectedFirmwareFileContext,
} from '../../DeviceFirmwareListsPage/DeviceFirmwareListsPage';
import { useInvalidateFirmwarePageQuery } from 'helpers/react-query/firmware-query-hooks';
import { useFieldArray } from 'react-hook-form';
import { useApplicationForm } from 'helpers/useApplicationForm';
import { getIsFormErrored } from 'helpers/react-hook-form-helpers';
import { useAvailableTargetsQuery } from 'helpers/react-query/notifications-query-hooks';
import { useIsoCodes } from 'helpers/useIsoCodes';
import { ReleaseCard } from './ReleaseCard';
import { getDevicesWhiteListsQueryKey } from 'helpers/react-query/query-keys';
import { WhiteListApi } from 'api/white-list-api';
import { useWhiteListCreateMutation } from 'helpers/react-query/devices-white-lists-query-hooks';
import { UploadWhiteListModal } from 'features/FirmwarePage/WhiteLists/Forms/UploadWhiteListModal/UploadWhiteListModal';

import AddIcon from 'assets/icons/add.svg';

import styles from './CreateReleasesModal.module.scss';

const defaultReleaseCard: ReleaseCardModel = {
  startDate: new Date(),
  type: TargetType.ByCountry,
  countries: [],
  distributionWays: [],
};

export const CreateReleasesModal: React.FC = () => {
  const { selectedFile, setSelectedFile } = useContext(SelectedFirmwareFileContext);
  return selectedFile ? <Modal selectedFile={selectedFile} setSelectedFile={setSelectedFile} /> : null;
};

const Modal: React.FC<{
  selectedFile: SelectedFirmwareFile;
  setSelectedFile: React.Dispatch<React.SetStateAction<SelectedFirmwareFile | null>>;
}> = ({ selectedFile, setSelectedFile }) => {
  const [isUploadWhiteListModalOpen, setIsUploadWhiteListModalOpen] = useState<boolean>(false);
  const {
    data: whiteLists,
    isLoading: areWhiteListsLoading,
    isRefetching: areWhiteListsRefetching,
    refetch: refetchWhiteLists,
  } = useQuery(getDevicesWhiteListsQueryKey(), ({ signal }) => WhiteListApi.getList({ signal }));

  const targets = useAvailableTargetsQuery();
  const stages = targets?.data?.stages?.map((x) => x.name) ?? [];
  const countries = targets?.data?.countries ?? [];

  const { file } = selectedFile;
  const { control, register, handleSubmit, formState, clearErrors, getValues, setValue } =
    useApplicationForm<FormReleases>({
      mode: 'all',
      defaultValues: {
        releases: file.targets.length ? mapFirmwareTargetDtosToReleaseCardModels(file.targets) : [defaultReleaseCard],
      },
    });
  const isFormErrored = getIsFormErrored(formState);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'releases',
  });

  const invalidate = useInvalidateFirmwarePageQuery();
  const onClose = () => setSelectedFile(null);

  const createWhiteListMutation = useWhiteListCreateMutation(async () => {
    handleOnCloseUploadWhiteListModal();
    await refetchWhiteLists();
  });
  const uploadFileTargetsMutation = useMutation(
    (targets: FirmwareTargetDto[]) => FirmwareApi.updateTargets(file.id, targets),
    {
      onSuccess: () => {
        invalidate();
        onClose();
      },
    },
  );

  // We should set undefined to id, because duplicated release shouldn't
  // have an id so as not to update the original release.
  const handleOnDuplicate = (release: ReleaseCardModel) =>
    append({
      ...release,
      id: undefined,
      distributionWays: release.distributionWays.map((dW) => {
        const { id, ...copy } = dW;
        return copy;
      }),
    });

  const handleOnDelete = (index: number) => remove(index);

  const onSubmit = async (data: FormReleases) =>
    await uploadFileTargetsMutation.mutateAsync(
      mapReleaseCardModelsToFirmwareTargetDtos(data.releases, whiteLists ?? []),
    );

  const handleClickOnAdd = () => append(defaultReleaseCard);

  const handleOnCloseUploadWhiteListModal = () => setIsUploadWhiteListModalOpen(false);

  const actions = [
    <Button color="secondary" variant="outlined" key={'cancel'} onClick={onClose}>
      Cancel
    </Button>,
    <Button type="submit" key={'save'} disabled={isFormErrored}>
      Save
    </Button>,
  ];

  return (
    <>
      <AppModal open={selectedFile.selectionType === 'settingRelease'} onClose={onClose}>
        <AppForm
          title="Set firmware release"
          description={`${file.firmwareGeneration}, FM ver ${file.version}`}
          onSubmit={handleSubmit(onSubmit)}
          isLoading={uploadFileTargetsMutation.isLoading || areWhiteListsLoading || areWhiteListsRefetching}
          actions={actions}
          helperText={formState.errors.root?.message}
          error={isFormErrored}
          onChange={() => clearErrors()}
        >
          <div className={styles.releasesModal}>
            {fields.map((release, index) => {
              return (
                <ReleaseCard
                  key={release.id}
                  stages={stages}
                  whiteLists={whiteLists ?? []}
                  countries={countries}
                  index={index}
                  register={register}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  errors={formState.errors.releases && formState.errors.releases[index]}
                  onDuplicate={handleOnDuplicate}
                  onDelete={handleOnDelete}
                  openUploadWhiteListModal={() => setIsUploadWhiteListModalOpen(true)}
                />
              );
            })}
          </div>
          <Button
            className={styles.addReleaseButton}
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={handleClickOnAdd}
            key={'add'}
          >
            Add release date
          </Button>
        </AppForm>
      </AppModal>
      {isUploadWhiteListModalOpen && (
        <UploadWhiteListModal
          title="Upload white list"
          open={isUploadWhiteListModalOpen}
          onClose={handleOnCloseUploadWhiteListModal}
          onSubmit={createWhiteListMutation.mutateAsync}
          isBusy={createWhiteListMutation.isLoading}
        />
      )}
    </>
  );
};
