import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { DropDownOption } from '../utils';

type Props = {
  onChange: (value: any) => void;
  options: DropDownOption[];
  placeholder: string;
  value?: any;
  helperText?: React.ReactNode;
  error?: boolean;
};

export const DropDown: React.FC<Props> = ({ onChange, options, placeholder, value, helperText, error }) => (
  <Autocomplete
    value={value}
    onChange={(_, selected) => {
      onChange(selected);
    }}
    getOptionLabel={(opt) => options.find((x) => x.value === opt)?.label ?? ''}
    options={options.map((x) => x.value)}
    sx={{
      ['& .MuiOutlinedInput-input']: {
        height: 20,
      },
    }}
    renderInput={(params) => <TextField {...params} placeholder={placeholder} error={error} helperText={helperText} />}
  />
);
