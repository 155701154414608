// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topPanel_ErSqH{width:100%;align-items:start;gap:16px;display:grid;grid-template-columns:.5fr 1fr}@media(width <= 560px){.topPanel_ErSqH{display:flex;flex-flow:column}}`, "",{"version":3,"sources":["webpack://./src/features/FirmwarePage/WhiteLists/TopPanel/TopPanel.module.scss"],"names":[],"mappings":"AAAA,gBACI,UAAA,CACA,iBAAA,CACA,QAAA,CAEA,YAAA,CACA,8BAAA,CAEA,uBARJ,gBASM,YAAA,CACA,gBAAA,CAAA","sourcesContent":[".topPanel {\n    width: 100%;\n    align-items: start;\n    gap: 16px;\n  \n    display: grid;\n    grid-template-columns: 0.5fr 1fr;\n  \n    @media (width <= 560px) {\n      display: flex;\n      flex-flow: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topPanel": `topPanel_ErSqH`
};
export default ___CSS_LOADER_EXPORT___;
