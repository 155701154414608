// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labelContainer_TPUab{display:flex;gap:5px}`, "",{"version":3,"sources":["webpack://./src/components/CheckBoxGroup/CheckboxGroup.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,OAAA","sourcesContent":[".labelContainer {\n    display: flex;\n    gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelContainer": `labelContainer_TPUab`
};
export default ___CSS_LOADER_EXPORT___;
