import { Button, IconButton, TextField } from '@mui/material';
import { AppForm } from 'components/AppForm/AppForm';
import { AppModal } from 'components/AppModal/AppModal';
import { Field } from 'components/Field/Field';
import { FileDragAndDrop } from 'components/FileDragAndDrop/FileDragAndDrop';
import { getIsFormErrored } from 'helpers/react-hook-form-helpers';
import { useApplicationForm } from 'helpers/useApplicationForm';
import React from 'react';
import DeleteIcon from 'assets/icons/small-close-icon.svg';
import CsvFileIcon from 'assets/icons/csv-file.svg';
import { Controller } from 'react-hook-form';
import { SaveWhiteListDto, WhiteListDto } from 'api/white-list-api';

import styles from './UploadWhiteListModal.module.scss';

type WhiteListFormModel = Partial<WhiteListDto> & { file?: File };

type UploadWhiteListModalProps = {
  title: string;
  open: boolean;
  defaultValue?: WhiteListDto;
  onClose: () => void;
  onSubmit: (whiteList: SaveWhiteListDto) => Promise<void>;
  isBusy?: boolean;
};

const FileNameLength = 50;
const DescriptionLength = 100;

export const UploadWhiteListModal: React.FC<UploadWhiteListModalProps> = ({
  title,
  open,
  defaultValue,
  onClose,
  onSubmit,
  isBusy,
}) => {
  const { control, register, handleSubmit, clearErrors, formState, getValues, setValue, setError } =
    useApplicationForm<WhiteListFormModel>({
      values: defaultValue
        ? {
            id: defaultValue.id,
            name: defaultValue.name,
            description: defaultValue.description,
            file: new File([], defaultValue.name),
          }
        : {
            name: '',
            description: '',
          },
    });
  const isFormErrored = getIsFormErrored(formState);

  // This regex expression does not allow to write spaces in the beginning and more than one after a symbol.
  const handleOnChangeStringInput = (value: string): string => value.replace(/^\s+|(?<= ) +/g, '');

  const nameField = register('name', {
    required: { value: true, message: 'Required' },
    maxLength: { value: FileNameLength, message: `Name length should not exceed ${FileNameLength} characters.` },
    onChange: (e) => setValue('name', handleOnChangeStringInput(e.target.value)),
  });
  const descriptionField = register('description', {
    maxLength: {
      value: DescriptionLength,
      message: `Description length should not exceed ${DescriptionLength} characters.`,
    },
    onChange: (e) => setValue('description', handleOnChangeStringInput(e.target.value)),
  });

  const uploadFile = (file?: File) => {
    // It uses shouldValidate flag to re-render.
    setValue('file', file, { shouldValidate: true });
  };

  const handleOnSubmit = async (whiteList: WhiteListFormModel) => {
    if (!whiteList.name) {
      setError('name', { message: 'Required', type: 'required' });
      return;
    }

    if (!whiteList.file) {
      setError('file', { message: 'Required', type: 'required' });
      return;
    }

    await onSubmit({
      id: whiteList.id,
      name: whiteList.name.trim(),
      description: whiteList.description?.trim(),
      // The condition below exists because user can update only name\description.
      serialNumbers: defaultValue ? (whiteList.file?.size ? whiteList.file : undefined) : whiteList.file,
    });
  };

  const handleFileUpload = async (fileList: FileList) => {
    const file = fileList.item(0);

    if (!file) {
      return;
    }

    if (!file.name.endsWith('.csv')) {
      setError('file', { message: 'Unsupported file extension.', type: 'validation' });
      return;
    }

    if (!getValues('name')) {
      setValue('name', file.name.replace('.csv', ''));
    }
    uploadFile(file);
  };

  const handleOnClear = () => {
    uploadFile();
  };

  const actions = [
    <Button color="secondary" variant="outlined" key={'cancel'} onClick={onClose}>
      Cancel
    </Button>,
    <Button type="submit" key={'save'} disabled={isFormErrored}>
      Save
    </Button>,
  ];

  return (
    <AppModal onClose={onClose} open={open}>
      <AppForm
        title={title}
        onSubmit={handleSubmit(handleOnSubmit)}
        isLoading={isBusy}
        actions={actions}
        helperText={formState.errors.root?.message}
        error={isFormErrored}
        onChange={() => clearErrors()}
      >
        <Field title={'Name'} fieldClassName={styles.fileNameInput}>
          <Controller
            control={control}
            name={nameField.name}
            render={({ field }) => (
              <TextField
                sx={{ width: '100%', maxWidth: 260 }}
                type="text"
                {...field}
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message}
              />
            )}
          />
          <span className={styles.fileExtension}>.csv</span>
        </Field>
        <Field title={'Comment'}>
          <Controller
            control={control}
            name={descriptionField.name}
            render={({ field }) => (
              <TextField
                sx={{ width: '100%' }}
                type="text"
                {...field}
                error={!!formState.errors.description}
                helperText={formState.errors.description?.message}
              />
            )}
          />
        </Field>
        <Field title={'File'}>
          {getValues('file') ? (
            <div className={styles.uploadedFileContainer}>
              <p className={styles.fileName} data-error={isFormErrored}>
                {getValues('file')?.name.endsWith('.csv') ? getValues('file')?.name : getValues('file')?.name + '.csv'}
              </p>
              <IconButton sx={{ width: '24px', height: '24px', margin: '5px', padding: '0px' }} onClick={handleOnClear}>
                <DeleteIcon />
              </IconButton>
            </div>
          ) : (
            <FileDragAndDrop
              acceptedExtensions=".csv"
              icon={<CsvFileIcon />}
              handleFiles={handleFileUpload}
              error={getIsFormErrored(formState)}
              inputId="file-upload"
              dragAreaChildren={
                <span className={styles.dragAndDropDescription}>.csv containing a list of device serial numbers</span>
              }
            />
          )}
        </Field>
      </AppForm>
    </AppModal>
  );
};
