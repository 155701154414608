import {
  DeviceWhiteListDto,
  DistributionType,
  DistributionWayDto,
  FirmwareTargetDto,
  TargetType,
} from 'api/firmware-api';
import { DropDownOption } from 'components/Select/utils';
import { addWhiteListFileExtension } from 'features/FirmwarePage/WhiteLists/utils';

export type DistributionTypeDescription = {
  type: DistributionType;
  label: string;
  hint?: string;
};

export type DistributionWay = {
  id?: string;
  type: DistributionType;
  stages?: string[];
};

export type ReleaseCardModel = {
  id?: string;
  startDate: Date;
  type: TargetType;
  countries?: string[];
  distributionWays: DistributionWay[];
  whiteListId?: string;
  partialReleasePercentage?: number;
};

export type FormReleases = {
  releases: ReleaseCardModel[];
};

export type ContriesSelector = 'Particular' | 'All' | null;
export type StagesSelector = 'Particular' | 'All' | null;

export const distributionTypesByWhiteList: DistributionType[] = [
  DistributionType.PrismaCloud,
  DistributionType.ClientPortal,
];

const distributionTypeDescriptions: DistributionTypeDescription[] = [
  {
    type: DistributionType.PrismaCloud,
    label: 'prisma CLOUD',
    hint: 'The firmware will be available for download on selected prisma CLOUD stages',
  },
  {
    type: DistributionType.ClientPortal,
    label: 'Kundenportal',
    hint: 'The firmware will be available for download on Kundenportal',
  },
  {
    type: DistributionType.OverTheAir,
    label: 'Over the air',
    hint: 'The firmware will be sent to the device via modem',
  },
  {
    type: DistributionType.PrismaApp,
    label: 'prisma APP',
    hint: "The firmware will be downloaded to the prisma APP user's phone and sent to the device via Bluetooth",
  },
];

export const distributionTypes = Object.keys(DistributionType).map((distributionType) => distributionType as DistributionType);

export const distributionTypeByTargetType = (targetType: TargetType) =>
  targetType === TargetType.ByCountry ? distributionTypes : [DistributionType.OverTheAir, DistributionType.PrismaApp];

export const getDistributionTypeLabel = (distributionType: DistributionType) =>
  distributionTypeDescriptions.find((item) => item.type === distributionType)?.label ?? 'Unknown';

export const getDistributionTypeHint = (distributionType: DistributionType) =>
  distributionTypeDescriptions.find((item) => item.type === distributionType)?.hint;

export const getDefaultContriesSelector = (release: ReleaseCardModel): ContriesSelector => {
  if (release.type === TargetType.ByWhiteList) {
    return null;
  }
  return release.countries && release.countries.length >= 0 ? 'Particular' : 'All';
};

export const getDefaultStagesSelector = (release: ReleaseCardModel): StagesSelector => {
  const pCloudDistributionWay = release.distributionWays.find((dW) => dW.type === DistributionType.PrismaCloud);
  if (!pCloudDistributionWay) {
    return null;
  }
  return pCloudDistributionWay.stages && pCloudDistributionWay.stages.length >= 0 ? 'Particular' : 'All';
};

export const mapFirmwareTargetDtosToReleaseCardModels = (targets: FirmwareTargetDto[]) =>
  targets.map((release): ReleaseCardModel => {
    const isTargetTypeByCountry = release.type === TargetType.ByCountry;

    return {
      id: release.id,
      startDate: new Date(release.startDate),
      type: release.type,
      countries: isTargetTypeByCountry ? release.countryTarget?.countries : undefined,
      whiteListId: !isTargetTypeByCountry ? release.whiteListTarget?.whiteList.id : undefined,
      partialReleasePercentage: !isTargetTypeByCountry
        ? release.whiteListTarget?.partialReleasePercentage ?? undefined
        : undefined,
      distributionWays: release.distributionWays.map(
        (dW): DistributionWay => ({
          id: dW.id,
          type: dW.type,
          stages: dW.byPrismaCloud?.stages,
        }),
      ),
    };
  });

export const mapReleaseCardModelsToFirmwareTargetDtos = (
  releases: ReleaseCardModel[],
  whiteLists: DeviceWhiteListDto[],
) =>
  releases.map((release): FirmwareTargetDto => {
    const isTargetTypeByCountry = release.type === TargetType.ByCountry;
    const prismaCloudDWIndex = release.distributionWays.findIndex((dW) => dW.type === DistributionType.PrismaCloud);
    const whiteList = whiteLists.find((list) => list.id === release.whiteListId);

    return {
      id: release.id,
      startDate: release.startDate.toISOString(),
      type: release.type,
      countryTarget: isTargetTypeByCountry ? { countries: release.countries } : undefined,
      distributionWays: release.distributionWays.map(
        (dW, index): DistributionWayDto => ({
          id: dW.id,
          type: dW.type,
          byPrismaCloud: prismaCloudDWIndex === index ? { stages: dW.stages } : undefined,
        }),
      ),
      whiteListTarget:
        !isTargetTypeByCountry && release.partialReleasePercentage && whiteList
          ? {
              partialReleasePercentage: release.partialReleasePercentage,
              whiteList: {
                id: whiteList.id,
                name: whiteList.name,
                description: whiteList.description ?? '',
              },
            }
          : undefined,
    };
  });

export const mapWhiteListsToDropDownOption = (whiteLists: DeviceWhiteListDto[]) =>
  whiteLists.map((whiteList): DropDownOption => ({ value: whiteList.id, label: addWhiteListFileExtension(whiteList.name) }));

export const getStagesDefaultValueByStagesSelector = (stagesSelector: StagesSelector): string[] | undefined => {
  return stagesSelector === 'Particular' ? [] : undefined;
};

export const changeDistributionWays = (
  checkedDistributionType: DistributionType,
  checked: boolean,
  currentDW: DistributionWay[],
): DistributionWay[] => {
  let changedDW: DistributionWay[] = [];
  if (checked) {
    const checkedDistributionWay: DistributionWay = {
      type: checkedDistributionType,
    };
    changedDW = [...currentDW, checkedDistributionWay];
  } else {
    changedDW = currentDW ? [...currentDW.filter((dW) => dW.type !== checkedDistributionType)] : [];
  }

  return changedDW;
};

export const getDistributionWayOptions = (targetType: TargetType, currentDistributionWays: DistributionWay[]) =>
  distributionTypeByTargetType(targetType).map((distributionType) => {
    const selectedDistributionTypes = currentDistributionWays.map((dW) => dW.type);
    return {
      value: distributionType,
      label: getDistributionTypeLabel(distributionType),
      selected: selectedDistributionTypes ? selectedDistributionTypes.includes(distributionType) : false,
      hint: getDistributionTypeHint(distributionType),
    };
  });
