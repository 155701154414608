import { useQuery } from '@tanstack/react-query';
import { getDownloadToketQueryKey } from 'helpers/react-query/query-keys';
import { DownloadTokenApi } from './downloadToken-api';

export type PageDto<TItem> = {
  items: TItem[];
  pageInfo: PageInfo;
};

type PageInfo = {
  currentPage: number;
  totalPages: number;
  totalItems: number;
};

export type QueryParameters = {
  pageNumber?: number;
  pageSize: number;
  sortingType: SortingType;
};

export enum SortingType {
  Ascending = 0,
  Descending = 1,
}

export const append = (data: FormData, propertyName: string, value: any) => {
  if (value instanceof Date) {
    appendString(data, propertyName, value.toUTCString());
  } else if (value instanceof Blob) {
    data.append(propertyName, value as Blob);
  } else if (typeof value === 'string' || value instanceof String) {
    appendString(data, propertyName, value as string);
  } else if (Array.isArray(value)) {
    appendArray(data, propertyName, value);
  } else if (!value) {
    return;
  } else {
    appendObject(data, propertyName, value);
  }
};

export const useDownloadFileByUrl = () => {
  const downloadTokenQuery = useQuery(getDownloadToketQueryKey(), () => DownloadTokenApi.getToken(), {
    enabled: false,
  });

  const fetchDownloadToken = async (): Promise<string> => {
    const { data: downloadToken } = await downloadTokenQuery.refetch();
    if (!downloadToken) {
      throw new Error('Failed to fetch download token.');
    }
    return downloadToken;
  };

  const fetchDownloadFile = async (getUrl: (downloadToken: string) => string) => {
    const downloadToken = await fetchDownloadToken();
    const url = getUrl(downloadToken);
    window.open(url, '_self');
  };

  return { fetchDownloadFile };
};

const appendObject = (data: FormData, propertyName: string, value: Object) =>
  Object.entries(value).forEach((x) => append(data, `${propertyName}.${x[0]}`, x[1]));

const appendString = (data: FormData, propertyName: string, value: string) =>
  data.append(propertyName, value as string);

const appendArray = (data: FormData, propertyName: string, value: any[]) => {
  value.forEach((entry, index) => append(data, `${propertyName}[${index}]`, entry));
};
