import { WhiteListApi, WhiteListDto } from 'api/white-list-api';
import { DotMenu, DotMenuOption } from 'components/DotMenu/DotMenu';
import React, { useContext } from 'react';
import { SelectedWhiteListContext } from '../DeviceWhiteListsPage/DeviceWhiteListsPage';
import { useDownloadFileByUrl } from 'api/common';

import menuStyles from 'styles/utils.module.scss';

export type DeviceWhiteListMenuProps = {
  whiteList: WhiteListDto;
};

export const DeviceWhiteListDotMenu: React.FC<DeviceWhiteListMenuProps> = ({ whiteList }) => {
  const options = useDeviceWhiteListMenuOptions(whiteList);
  return <DotMenu options={options} containerProps={{ className: menuStyles.tableRowEndMenu }} />;
};

const useDeviceWhiteListMenuOptions = (whiteList: WhiteListDto): DotMenuOption[] => {
  const { setSelectedWhiteList } = useContext(SelectedWhiteListContext);
  const { fetchDownloadFile } = useDownloadFileByUrl();

  const handleDownloadClick = async () => {
    const getUrlFunc = (downloadToken: string) => WhiteListApi.generateGettingWhiteListUrl(whiteList.id, downloadToken);
    await fetchDownloadFile(getUrlFunc);
  };

  const opts: DotMenuOption[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: () => setSelectedWhiteList({ whiteList: whiteList, selectionType: 'editing' }),
    },
    {
      key: 'download',
      text: 'Download',
      onClick: () => handleDownloadClick(),
    },
    {
      key: 'delete',
      text: 'Delete',
      type: 'danger',
      onClick: () => setSelectedWhiteList({ whiteList: whiteList, selectionType: 'deleting' }),
    },
  ];

  return opts;
};
