export type Comparer<TElement> = (left: TElement, right: TElement) => boolean;

export const groupBy = <TElement, TKey>(
  source: TElement[],
  keySelector: (item: TElement) => TKey,
  comparer: Comparer<TKey> = defaultComparer,
) =>
  distinct(source.map(keySelector), comparer).map((x) => ({
    key: x,
    values: source.filter((y) => comparer(keySelector(y), x)),
  }));

export const distinct = <TElement>(source: TElement[], comparer: Comparer<TElement> = defaultComparer) =>
  source.filter((x, i, source) => source.findIndex((y) => comparer(x, y)) === i);

const defaultComparer: Comparer<any> = (left, right) => left === right;
