import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';

import { FirmwareFileActions, UploadFirmwareModel } from '../useUploadFirmwareFilesForm/useUploadFirmwareFilesForm';
import { useUploadFirmwareFilesColumns } from './useUploadFirmwareFilesColumns/useUploadFirmwareFilesColumns';
import { FieldArrayWithId } from 'react-hook-form';
import { useMemo } from 'react';

export type UploadFirmwareFilesTableAttributes = {
  actions: FirmwareFileActions;
  files: FieldArrayWithId<UploadFirmwareModel, 'files', 'id'>[];
};

export const useUploadFirmwareFilesTable = ({ actions, files }: UploadFirmwareFilesTableAttributes) => {
  const columns = useUploadFirmwareFilesColumns({
    actions,
  });

  return useReactTable<FieldArrayWithId<UploadFirmwareModel, 'files', 'id'>>({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    data: useMemo(() => files, [files]),
    columns: columns,
  });
};
