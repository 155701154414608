// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.richToolbar_IjB56{background-color:rgba(0,0,0,.05);border:solid #d9d9d9;border-width:1px 1px 0px 1px;border-radius:4px 4px 0px 0px;display:flex;flex-direction:row}.richToolbarSection_Dl5q4{border:none;padding:2px;display:flex;gap:2px}.richToolbarSection_Dl5q4:not(:first-child){border:solid #d9d9d9;border-width:0px 0px 0px 1px !important}`, "",{"version":3,"sources":["webpack://./src/components/RichText/RichToolbar/RichToolbar.module.scss"],"names":[],"mappings":"AAAA,mBACE,gCAAA,CACA,oBAAA,CACA,4BAAA,CACA,6BAAA,CACA,YAAA,CACA,kBAAA,CAGF,0BACE,WAAA,CACA,WAAA,CAEA,YAAA,CACA,OAAA,CAEA,4CACE,oBAAA,CACA,uCAAA","sourcesContent":[".richToolbar {\n  background-color: rgba(0, 0, 0, 0.05);\n  border: solid #d9d9d9;\n  border-width: 1px 1px 0px 1px;\n  border-radius: 4px 4px 0px 0px;\n  display: flex;\n  flex-direction: row;\n}\n\n.richToolbarSection {\n  border: none;\n  padding: 2px;\n\n  display: flex;\n  gap: 2px;\n\n  &:not(:first-child) {\n    border: solid #d9d9d9;\n    border-width: 0px 0px 0px 1px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"richToolbar": `richToolbar_IjB56`,
	"richToolbarSection": `richToolbarSection_Dl5q4`
};
export default ___CSS_LOADER_EXPORT___;
