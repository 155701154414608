// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cell_uRYq6 div{display:flex;align-items:center}.fileName_udDac{word-break:break-all;max-width:200px}.menu_W1Y4u{padding:0 !important}.comment_koknM{word-break:break-all}`, "",{"version":3,"sources":["webpack://./src/features/FirmwarePage/WhiteLists/DeviceWhiteListsPage/DeviceWhiteListsPage.module.scss"],"names":[],"mappings":"AAAA,gBACI,YAAA,CACA,kBAAA,CAGJ,gBACI,oBAAA,CACA,eAAA,CAGJ,YACI,oBAAA,CAGJ,eACI,oBAAA","sourcesContent":[".cell div {\n    display: flex;\n    align-items: center;\n}\n\n.fileName {\n    word-break: break-all;\n    max-width: 200px;\n}\n\n.menu {\n    padding: 0 !important;\n}\n\n.comment {\n    word-break: break-all;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": `cell_uRYq6`,
	"fileName": `fileName_udDac`,
	"menu": `menu_W1Y4u`,
	"comment": `comment_koknM`
};
export default ___CSS_LOADER_EXPORT___;
