import { createRoute } from 'react-router-url-params';

export const Links = {
  Unauthorized: {
    Login: createRoute('login'),
  },
  Authorized: {
    Notifications: createRoute('notifications'),
    Firmware: createRoute('firmware'),
    FirmwareFiles: createRoute('files'),
    WhiteLists: createRoute('white-list'),
  },
};
