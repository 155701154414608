import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { append, PageDto, QueryParameters } from './common';
import * as url from 'url';
import { appUrl } from '../index';

export type WhiteListDto = {
  id: string;
  name: string;
  description?: string;
  size: number;
  lastChangedAt: string;
};

export type SaveWhiteListDto = {
  id?: string;
  name: string;
  description?: string;
  serialNumbers?: File;
};

export const WhiteListSortingAttributes = ['name', 'lastChangedAt'] as const;
export type WhiteListSortingAttribute = (typeof WhiteListSortingAttributes)[number];

export type WhiteListsQueryParameters = QueryParameters & {
  name?: string;
  sortingAttribute: WhiteListSortingAttribute;
};

export const WhiteListApi = {
  async getPage(
    requestParams: WhiteListsQueryParameters,
    options?: AxiosRequestConfig,
  ): Promise<PageDto<WhiteListDto>> {
    const params = url.format({ query: requestParams });
    const result: AxiosResponse<PageDto<WhiteListDto>> = await axios.get(`api/firmware/white-lists${params}`, options);

    return result.data;
  },

  async getList(options?: AxiosRequestConfig): Promise<WhiteListDto[]> {
    const result: AxiosResponse<WhiteListDto[]> = await axios.get('api/firmware/white-lists/all', options);

    return result.data;
  },

  async create(newWhiteList: SaveWhiteListDto, options?: AxiosRequestConfig): Promise<void> {
    const data = new FormData();
    append(data, 'dto', newWhiteList);

    await axios.post('api/firmware/white-lists/upload', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...options,
    });
  },

  async update(whiteList: SaveWhiteListDto, options?: AxiosRequestConfig): Promise<void> {
    const data = new FormData();
    append(data, 'dto', whiteList);

    await axios.post(`api/firmware/white-lists/${whiteList.id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...options,
    });
  },

  async delete(id: string, options?: AxiosRequestConfig): Promise<void> {
    await axios.delete(`api/firmware/white-lists/${id}`, options);
  },

  generateGettingWhiteListUrl(id: string, downloadToken: string) {
    const params = url.format({ query: { downloadToken } });
    return `${appUrl}/api/firmware/white-lists/${id}/download${params}`;
  },
};
