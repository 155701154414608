import clsx from 'clsx';
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Links } from '../../navigation/routes';

import styles from './FirmwarePage.module.scss';

const FirmwarePageTabs = [
  {
    link: Links.Authorized.FirmwareFiles.route,
    title: 'Files',
  },
  {
    link: Links.Authorized.WhiteLists.route,
    title: 'White lists',
  },
];

export const FirmwarePage: React.FC = () => {
  return (
    <>
      <div className={styles.tabs}>
        {FirmwarePageTabs.map((opt) => (
          <NavLink
            key={opt.title}
            className={({ isActive }) => clsx(styles.tabHeader, isActive && styles.activeTabHeader)}
            to={opt.link}
          >
            {opt.title}
          </NavLink>
        ))}
      </div>
      <Outlet />
    </>
  );
};
