import { useState } from 'react';

import { DeviceFirmwareGeneration, FirmwareApi, MaxRequestSize, UploadFirmwareContentDto } from 'api/firmware-api';
import { getProgressEventHandler, ProgressHandler } from 'helpers/axiosUtils';
import { bytesToMegaBytes, getFilesCumulativeSize } from 'helpers/fileUtils';
import { useApplicationForm } from 'helpers/useApplicationForm';
import { useFieldArray, UseFormRegister } from 'react-hook-form';
import { transformFirmwareUploads } from './firmwareUploadUtils';
import { transformProcessedFiles, useFirmwareFilesProcessor } from './useFirmwareFilesProcessor';

export type UploadFirmwareFileModel = {
  content: UploadFirmwareContentDto;
  generation: DeviceFirmwareGeneration;
  version: string;
};

export type UploadFirmwareModel = { files: UploadFirmwareFileModel[] };

export type FirmwareFileActions = {
  removeFile: (index: number) => void;
  register: UseFormRegister<UploadFirmwareModel>;
};

export type FirmwareFilesSelector = (items: UploadFirmwareFileModel[]) => UploadFirmwareFileModel[];

export const useUploadFirmwareFilesForm = (onSubmitSuccess: () => void, handleProgress: ProgressHandler) => {
  const [isLoading, setIsLoading] = useState(false);

  const form = useApplicationForm<UploadFirmwareModel>({
    mode: 'all',
    values: {
      files: [],
    },
  });
  const { handleSubmit, setError, formState, register, control } = form;
  const { remove, append, fields } = useFieldArray({ control, name: 'files' });

  register('files', {
    validate: (files) =>
      getFirmwareFilesCumulativeSize(files) <= MaxRequestSize.Bytes || 'Files total size cannot exceed 512MB.',
  });

  const processFiles = useFirmwareFilesProcessor();
  const handleUpload = async (fileList: FileList) => {
    setIsLoading(true);

    const { errors, values } = await processFiles([...fileList]);
    append(transformProcessedFiles(values));

    if (errors.length)
      setError('root', {
        message: errors.join(';'),
      });

    setIsLoading(false);
  };

  const onSubmit = async ({ files }: UploadFirmwareModel) => {
    await FirmwareApi.upload(transformFirmwareUploads(files), {
      onUploadProgress: getProgressEventHandler(handleProgress),
    });
    onSubmitSuccess();
  };

  const actions: FirmwareFileActions = {
    removeFile: remove,
    register: register,
  };

  return {
    ...form,
    formState,
    handleUpload,
    actions,
    handleSubmit: handleSubmit(onSubmit),
    files: fields,
    sizeLimitMessage: getSizeLimitMessage(fields),
    isBusy: isLoading || formState.isSubmitting,
  };
};

const getFirmwareFilesCumulativeSize = (files: UploadFirmwareFileModel[]) =>
  getFilesCumulativeSize(files.map((x) => x.content.file));

const getSizeLimitMessage = (files: UploadFirmwareFileModel[]) => {
  if (files.length === 0) return null;

  const size = getFirmwareFilesCumulativeSize(files);
  return `Uploaded ${bytesToMegaBytes(size)}/${MaxRequestSize.MegaBytes} MB.`;
};
