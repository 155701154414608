import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';

import { FirmwareRecordDto, FirmwareTargetDto, getFirmwareGenerationInfo } from 'api/firmware-api';
import { AppHint } from 'components/AppHint/AppHint';
import { SortableHeader, SortingControl } from 'components/SortableHeader/SortableHeader';
import { FirmwareContentTypeIcon } from 'features/FirmwarePage/components/FirmwareContentTypeIcon';
import { formatAsDate } from 'helpers/dateUtils';
import { FirmwareFileMenu } from './FirmwareFileMenu/FirmwareFileMenu';
import { FirmwareTarget } from './FirmwareRelease/FirmwareTarget';

import styles from './DeviceFirmwareListsPage.module.scss';

export type FirmwarePageColumnsAttributes = {
  uploadedAtSortingControl: SortingControl;
  versionSortingControl: SortingControl;
};

const columnHelper = createColumnHelper<FirmwareRecordDto>();

export const useFirmwarePageColumns: (props: FirmwarePageColumnsAttributes) => ColumnDef<FirmwareRecordDto>[] = ({
  uploadedAtSortingControl,
  versionSortingControl,
}) =>
  useMemo(
    () => [
      {
        id: 'contentTypes',
        accessorKey: 'contentTypes',
        enableSorting: false,
        cell: ({ row }) => (
          <div>
            {row.original.availableContent.map((x) => (
              <FirmwareContentTypeIcon type={x} key={x} />
            ))}
          </div>
        ),
        header: () => null,
        size: 24,
        meta: {
          minSize: 32,
          maxSize: 32,
          bodyCellProps: {
            className: styles.contentTypesCell,
          },
        },
      },
      {
        id: 'generation',
        accessorKey: 'generation',
        enableSorting: true,
        cell: ({ row }) => (
          <div className={styles.generation}>{getFirmwareGenerationInfo(row.original.firmwareGeneration).name}</div>
        ),
        header: 'GENERATION',
        size: 120,
        meta: {
          minSize: 120,
          bodyCellProps: {
            className: styles.releaseTargetsCell,
          },
          headCellProps: {
            className: styles.releaseTargetsCell,
          },
        },
      },
      {
        id: 'version',
        accessorKey: 'version',
        enableSorting: false,
        cell: ({ row }) => <div className={styles.version}>{row.original.version}</div>,
        header: () => 'FW VERSION',
        size: 110,
        meta: {
          minSize: 110,
        },
      },
      {
        id: 'releaseTargets',
        accessorKey: 'releaseTargets',
        enableSorting: false,
        cell: ({ row }) => <FirmwareTargets targets={row.original.targets} />,
        header: () => 'RELEASE DATE',
        size: 170,
        meta: {
          minSize: 120,
        },
      },
      {
        id: 'uploaded',
        accessorKey: 'uploaded',
        enableSorting: true,
        cell: ({ row }) => (
          <div className={styles.uploaded}>
            <span className={styles.uploadedAt}>{formatAsDate(row.original.uploadedAt)}</span>
            <AppHint
              openDelay={60}
              component={<span className={styles.uploadedBy}>{row.original.uploadedBy}</span>}
              children={row.original.uploadedBy}
            />
          </div>
        ),
        header: () => <SortableHeader sortingControl={uploadedAtSortingControl} content={'UPLOADED'} />,
        size: 170,
        meta: {
          minSize: 120,
        },
      },
      columnHelper.display({
        id: 'menu',
        cell: ({ row }) => <FirmwareFileMenu file={row.original} />,
        header: () => null,
        size: 0,
        meta: {
          bodyCellProps: {
            className: styles.menuCell,
          },
          minSize: 40,
        },
      }),
    ],
    [uploadedAtSortingControl, versionSortingControl],
  );

type FirmwareTargetsProps = {
  targets: FirmwareTargetDto[];
};

const FirmwareTargets: React.FC<FirmwareTargetsProps> = ({ targets }) =>
  targets.length ? (
    <div className={styles.releaseTargets}>
      {targets.map((target) => (
        <FirmwareTarget target={target} key={target.id} />
      ))}
    </div>
  ) : (
    <span className={styles.noDataLabel}>No date</span>
  );
