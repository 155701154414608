import React from 'react';
import { Button } from '@mui/material';
import { SearchInput } from 'components/SearchInput/SearchInput';

import styles from './TopPanel.module.scss';

type Props = {
  searchValue: string;
  onSearchInputStop?: (newValue: string) => void;
  onCreateButtonClick: () => void;
  requestDelay: number;
  placeholder?: string;
};

export const TopPanel: React.FC<Props> = ({
  searchValue,
  onSearchInputStop = () => undefined,
  onCreateButtonClick,
  placeholder,
  requestDelay,
}) => {
  return (
    <>
      <div className={styles.topPanel}>
        <SearchInput
          value={searchValue}
          onStop={onSearchInputStop}
          requestDelay={requestDelay}
          placeholder={placeholder}
        />
        <Button
          disableRipple
          sx={{
            width: '150px',
            marginLeft: 'auto',
          }}
          onClick={onCreateButtonClick}
          children={'Upload white list'}
        />
      </div>
    </>
  );
};
