// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileNameInput_d010p{display:flex;flex-direction:row;gap:10px}.fileExtension_jSXoU{padding-top:10px;color:#6e6e6e}.uploadedFileContainer_IKEe0{display:flex;align-items:center}.fileName_L8r7P{overflow-wrap:break-word;max-width:265px}.dragAndDropDescription_Va_qH{font-size:14px;color:#a6a6a6;text-align:center}`, "",{"version":3,"sources":["webpack://./src/features/FirmwarePage/WhiteLists/Forms/UploadWhiteListModal/UploadWhiteListModal.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA,qBACI,YAAA,CACA,kBAAA,CACA,QAAA,CAGJ,qBACI,gBAAA,CACA,aCYM,CDTV,6BACI,YAAA,CACA,kBAAA,CAGJ,gBACI,wBAAA,CACA,eAAA,CAGJ,8BACI,cAAA,CACA,aCJM,CDKN,iBAAA","sourcesContent":["@import '/src/styles/variables.scss';\n\n.fileNameInput {\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n}\n\n.fileExtension {\n    padding-top: 10px;\n    color: $grey-60;\n}\n\n.uploadedFileContainer {\n    display: flex;\n    align-items: center;\n}\n\n.fileName {\n    overflow-wrap: break-word;\n    max-width: 265px;\n}\n\n.dragAndDropDescription {\n    font-size: 14px;\n    color: $grey-40;\n    text-align: center;\n}","$primary: #a9cb00;\n$primary-dark: #80b000;\n$normal: #4f4f4f;\n\n$danger-light: #ffccc9;\n$danger: #e74c3c;\n$info-light: #abd7ff;\n$info: #46a3f9;\n$warning-background: #fee5cd;\n$warning-light: #ffeb8e;\n$warning: #f2994a;\n$background: #ffffff;\n\n$text: #1d1d1d;\n\n$border-input: #d3dcea;\n$border-disabled: #e7e7e7;\n$border-focused: $primary;\n$grey-10: #f3f3f3;\n$grey-20: #dfdfdf;\n$grey-30: #c4c4c4;\n$grey-40: #a6a6a6;\n$grey-60: #6e6e6e;\n$grey-70: #525252;\n$caret-color: #344966;\n\n$title-primary: #6e6e6e;\n$title-font-weight: 600;\n$title-font-size: 14px;\n$title-line-height: 16px;\n\n$normal-input-height: 32px;\n$form-input-height: 32px;\n$input-padding: 12px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileNameInput": `fileNameInput_d010p`,
	"fileExtension": `fileExtension_jSXoU`,
	"uploadedFileContainer": `uploadedFileContainer_IKEe0`,
	"fileName": `fileName_L8r7P`,
	"dragAndDropDescription": `dragAndDropDescription_Va_qH`
};
export default ___CSS_LOADER_EXPORT___;
