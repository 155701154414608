import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import { AppHint } from 'components/AppHint/AppHint';

import styles from './CheckboxGroup.module.scss';

type Option = {
  value: string;
  label: string;
  selected: boolean;
  hint?: string;
};

type Props = {
  options: Option[];
  onChange: (value: string, checked: boolean) => void;
  error?: boolean;
  helperText?: React.ReactNode;
};

export const CheckboxGroup: React.FC<Props> = ({ options, onChange, error, helperText }) => (
  <FormControl error={error}>
    <FormGroup>
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          checked={option.selected}
          onChange={(_, checked) => {
            onChange(option.value, checked);
          }}
          control={<Checkbox size="small" sx={{ color: '#A6A6A6' }} />}
          label={
            <div className={styles.labelContainer}>
              {option.label}
              {option.hint && <AppHint>{option.hint}</AppHint>}
            </div>
          }
          value={option.value}
        />
      ))}
    </FormGroup>
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
);
