import { DeviceFirmwareGeneration, UploadFirmwareFileDto } from 'api/firmware-api';
import { Comparer, groupBy } from 'helpers/collectionUtils';
import { UploadFirmwareFileModel } from './useUploadFirmwareFilesForm';

type GenerationVersionGroup = {
  generation: DeviceFirmwareGeneration;
  version: string;
};

export const transformFirmwareUploads = (uploads: UploadFirmwareFileModel[]): UploadFirmwareFileDto[] =>
  groupBy(uploads, selector, comparer).map((x) => ({
    firmwareGeneration: x.key.generation,
    version: x.key.version,
    content: x.values.map((y) => y.content),
  }));

const selector = (x: UploadFirmwareFileModel): GenerationVersionGroup => ({
  generation: x.generation,
  version: x.version,
});

const comparer: Comparer<GenerationVersionGroup> = (left, right) =>
  right.generation === left.generation && right.version === left.version;
