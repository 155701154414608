import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as url from 'url';

import { megaBytesToBytes } from 'helpers/fileUtils';
import { appUrl } from '../index';
import { PageDto, QueryParameters, append } from './common';

export type MaxRequestSizeType = {
  MegaBytes: number;
  Bytes: number;
};

export const MaxRequestSize: MaxRequestSizeType = {
  MegaBytes: 512,
  Bytes: megaBytesToBytes(512),
};

export enum DeviceGeneration {
  P2_8 = 'P2_8',
  P3 = 'P3',
  P3_4 = 'P3_4',
  P5 = 'P5',
}

export enum P28Subtype {
  Classic = 'Classic',
  Npsb = 'Npsb',
  Wireless = 'Wireless',
}

export enum DeviceFirmwareGeneration {
  P2_8 = 'P2_8',
  P2_8NBSP = 'P2_8NBSP',
  P2_8W = 'P2_8W',
  P3 = 'P3',
  P3_X = 'P3_X',
  P5 = 'P5',
}

export enum DistributionType {
  PrismaCloud = 'PrismaCloud',
  ClientPortal = 'ClientPortal',
  OverTheAir = 'OverTheAir',
  PrismaApp = 'PrismaApp',
}

export enum TargetType {
  ByCountry = 'ByCountry',
  ByWhiteList = 'ByWhiteList',
}

export enum FirmwareContentType {
  FwFile = 'FwFile',
  FwChunks = 'FwChunks',
}

export const FirmwareGenerations = Object.keys(DeviceFirmwareGeneration) as unknown as DeviceFirmwareGeneration[];
export type FullDeviceGeneration = {
  deviceGeneration: DeviceGeneration;
  p28Subtype?: P28Subtype;
};
export type GenerationInfo = FullDeviceGeneration & {
  name: string;
  firmwareGeneration: DeviceFirmwareGeneration;
  content: GenerationContentInfo[];
};

export type GenerationContentInfo = {
  name: RegExp;
  nameExample: string;
  type: FirmwareContentType;
};

export type FirmwareRecordDto = {
  id: string;
  fileName: string;
  firmwareGeneration: DeviceFirmwareGeneration;
  targets: FirmwareTargetDto[];
  version: string;
  uploadedAt: string;
  uploadedBy: string;
  availableContent: FirmwareContentType[];
};

export type UploadFirmwareFileDto = {
  content: UploadFirmwareContentDto[];
  firmwareGeneration: DeviceFirmwareGeneration;
  version: string;
};

export type UploadFirmwareContentDto = {
  file: File;
  type: FirmwareContentType;
};

export type UpdateFirmwareRecordDto = {
  id: string;
  targets: FirmwareTargetDto[];
};

export type PrismaCloudDistributionWayDto = {
  stages?: string[];
};

export type DistributionWayDto = {
  id?: string;
  type: DistributionType;
  byPrismaCloud?: PrismaCloudDistributionWayDto;
};

export type DistributionWayConstraints = {
  type: DistributionType;
  requiredContent: FirmwareContentType;
  generations: DeviceFirmwareGeneration[];
};

export type CountryTargetDto = {
  countries?: string[];
};

export type DeviceWhiteListDto = {
  id: string;
  name: string;
  description?: string;
};

export type WhiteListTargetDto = {
  whiteList: DeviceWhiteListDto;
  partialReleasePercentage: number;
};

export type FirmwareTargetDto = {
  id?: string;
  startDate: string;
  type: TargetType;
  countryTarget?: CountryTargetDto;
  distributionWays: DistributionWayDto[];
  whiteListTarget?: WhiteListTargetDto;
};

export const FirmwareSortingAttributes = ['version', 'uploadedAt'] as const;
export type FirmwareSortingAttribute = (typeof FirmwareSortingAttributes)[number];
export type FirmwareQueryParameters = QueryParameters & {
  generations?: string[];
  countries?: string[];
  stages?: string[];
  distributionTypes?: string[];
  sortingAttribute: FirmwareSortingAttribute;
};

export const FirmwareApi = {
  async get(requestParams: FirmwareQueryParameters, options?: AxiosRequestConfig): Promise<PageDto<FirmwareRecordDto>> {
    const params = url.format({ query: requestParams });
    const result: AxiosResponse<PageDto<FirmwareRecordDto>> = await axios.get(`api/firmware/files${params}`, options);

    return result.data;
  },

  async getContentLink(id: string, type: FirmwareContentType): Promise<string> {
    const response = await axios.get(`${appUrl}/api/firmware/files/${id}/content/${type}`);
    return response.data;
  },

  async upload(files: UploadFirmwareFileDto[], options?: AxiosRequestConfig): Promise<void> {
    const data = new FormData();
    append(data, 'files', files);

    await axios.post(`api/firmware/files`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...options,
    });
  },

  async delete(fileId: string, options?: AxiosRequestConfig): Promise<void> {
    await axios.delete(`api/firmware/files/${fileId}`, options);
  },

  async updateTargets(fileId: string, targets: FirmwareTargetDto[], options?: AxiosRequestConfig): Promise<void> {
    await axios.post(`api/firmware/files/${fileId}/targets`, targets, options);
  },
};

export const getFullGenerationInfo = (file: FullDeviceGeneration) => {
  const info = GenerationsInfoMap.find(
    (x) => x.deviceGeneration === file.deviceGeneration && x.p28Subtype === file.p28Subtype,
  );
  if (!info) throw Error(`Invalid generation: ${file.deviceGeneration} and ${file.p28Subtype}`);
  return info;
};

export const getFirmwareGenerationInfo = (generation: DeviceFirmwareGeneration) => {
  const info = GenerationsInfoMap.find((x) => x.firmwareGeneration === generation);
  return info!;
};

// Pattern (?<version>(?:\d+\.)*\d+) is used across multiple expressions to match firmware version.
// Extracting it into a variable and reusing (e.g. new RegExp(`.*LMT35131-v${version}\\.hex`))
// produces a very strange bug where the capturing group name is lost: /.*\d{8}_((?:\d+\.)*\d+)_OverTheAir.zip$/.
// At the same time, browser console yields correct results: /.*\d{8}_(?<version>(?:\d+\.)*\d+)_OverTheAir.zip$/.
export const GenerationsInfoMap: GenerationInfo[] = [
  {
    firmwareGeneration: DeviceFirmwareGeneration.P2_8,
    name: 'P2.8',
    deviceGeneration: DeviceGeneration.P2_8,
    p28Subtype: P28Subtype.Classic,
    content: [{ type: FirmwareContentType.FwFile, name: /.*Update28.bin/, nameExample: 'Update28.bin' }],
  },
  {
    firmwareGeneration: DeviceFirmwareGeneration.P2_8NBSP,
    name: 'P2.8NPSB',
    deviceGeneration: DeviceGeneration.P2_8,
    p28Subtype: P28Subtype.Npsb,
    content: [{ type: FirmwareContentType.FwFile, name: /.*Update35196.bin/, nameExample: 'Update35196.bin' }],
  },
  {
    firmwareGeneration: DeviceFirmwareGeneration.P2_8W,
    name: 'P2.8W',
    deviceGeneration: DeviceGeneration.P2_8,
    p28Subtype: P28Subtype.Wireless,
    content: [
      { type: FirmwareContentType.FwFile, name: /.*Update35298.bin/, nameExample: 'Update35298.bin' },
      {
        type: FirmwareContentType.FwChunks,
        name: /.*\d{8}_(?<version>(?:\d+\.)*\d+)_OverTheAir.zip$/,
        nameExample: '<DATE>_<VERSION>_OverTheAir.zip',
      },
    ],
  },
  {
    firmwareGeneration: DeviceFirmwareGeneration.P3,
    name: 'P3',
    deviceGeneration: DeviceGeneration.P3,
    content: [
      {
        type: FirmwareContentType.FwFile,
        name: /.*Update_weinmann_respiratory_device.zip/,
        nameExample: 'Update_weinmann_respiratory_device.zip',
      },
    ],
  },
  {
    firmwareGeneration: DeviceFirmwareGeneration.P3_X,
    name: 'pVENT (P3.X)',
    deviceGeneration: DeviceGeneration.P3_4,
    content: [
      {
        type: FirmwareContentType.FwFile,
        name: /.*Update_weinmann_respiratory_device_P3_2.zip/,
        nameExample: 'Update_weinmann_respiratory_device_P3_2.zip',
      },
    ],
  },
  {
    firmwareGeneration: DeviceFirmwareGeneration.P5,
    name: 'P5',
    deviceGeneration: DeviceGeneration.P5,
    content: [
      {
        type: FirmwareContentType.FwFile,
        name: /.*LMT35131-v(?<version>(?:\d+\.)*\d+)\.hex/,
        nameExample: 'LMT35131-v<VERSION>.hex',
      },
    ],
  },
];

export const DistributionConstraints: DistributionWayConstraints[] = [
  { type: DistributionType.PrismaApp, requiredContent: FirmwareContentType.FwFile, generations: FirmwareGenerations },
  {
    type: DistributionType.ClientPortal,
    requiredContent: FirmwareContentType.FwFile,
    generations: FirmwareGenerations,
  },
  {
    type: DistributionType.PrismaApp,
    requiredContent: FirmwareContentType.FwChunks,
    generations: [DeviceFirmwareGeneration.P2_8W],
  },
  {
    type: DistributionType.OverTheAir,
    requiredContent: FirmwareContentType.FwChunks,
    generations: [DeviceFirmwareGeneration.P2_8W],
  },
];

export const getFirmwareContentTypeName = (type: FirmwareContentType) => {
  const content = FirmwareContentTypeMap.find((x) => x.type === type);
  if (!content) throw new Error(`Invalid firmware content type: ${type}`);

  return content.name;
};
export const FirmwareContentTypeMap = [
  {
    type: FirmwareContentType.FwFile,
    name: 'file',
  },
  {
    type: FirmwareContentType.FwChunks,
    name: 'chunks',
  },
];
