import React from 'react';

import { FirmwareContentType } from 'api/firmware-api';
import ChipIcon from 'assets/icons/chip.svg';
import ChunksIcon from 'assets/icons/chunks.svg';
import { AppHint } from 'components/AppHint/AppHint';

export type FirmwareContentTypeIconProps = {
  type: FirmwareContentType;
};

export const FirmwareContentTypeIcon: React.FC<FirmwareContentTypeIconProps> = ({ type }) => {
  const contentType = ContentTypes.find((x) => x.type === type);
  if (!contentType) throw new Error(`Invalid content type: ${type}`);

  return (
    <AppHint openDelay={60} component={contentType.component()}>
      {contentType.name}
    </AppHint>
  );
};

const ContentTypes = [
  {
    type: FirmwareContentType.FwFile,
    component: ChipIcon,
    name: 'File',
  },
  {
    type: FirmwareContentType.FwChunks,
    component: ChunksIcon,
    name: 'Chunks',
  },
];
