import { FirmwareApi, FirmwareContentType, FirmwareRecordDto, getFirmwareContentTypeName } from 'api/firmware-api';
import { DotMenu, DotMenuOption } from 'components/DotMenu/DotMenu';
import React, { useContext } from 'react';
import { SelectedFirmwareFileContext } from '../DeviceFirmwareListsPage';

import menuStyles from 'styles/utils.module.scss';

export type FirmwareFileMenuProps = {
  file: FirmwareRecordDto;
};

export const FirmwareFileMenu: React.FC<FirmwareFileMenuProps> = ({ file }) => {
  const options = useFirmwareFileOptions(file);
  return <DotMenu options={options} containerProps={{ className: menuStyles.tableRowEndMenu }} />;
};

const useFirmwareFileOptions: (record: FirmwareRecordDto) => DotMenuOption[] = (record) => {
  const { setSelectedFile } = useContext(SelectedFirmwareFileContext);
  const isUsed = record.targets.length > 0;

  const handleDownload = async (type: FirmwareContentType) => {
    const link = await FirmwareApi.getContentLink(record.id, type);
    window.open(link, '_self');
  };

  const downloadOptions: DotMenuOption[] = record.availableContent.map((x) => ({
    key: `download-${x}`,
    text: `Download ${getFirmwareContentTypeName(x)}`,
    onClick: () => handleDownload(x),
  }));

  const opts: DotMenuOption[] = [
    {
      key: 'setRelease',
      text: 'Configure release',
      onClick: () => setSelectedFile({ file: record, selectionType: 'settingRelease' }),
    },
    ...downloadOptions,
    {
      key: 'delete',
      text: 'Delete',
      onClick: () => setSelectedFile({ file: record, selectionType: 'deleting' }),
      type: 'warning',
      disabled: isUsed,
      hint: { children: isUsed && 'Only unused files can be deleted.', openDelay: 350 },
    },
  ];

  return opts;
};
