import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { WhiteListDto } from 'api/white-list-api';
import React, { useMemo } from 'react';
import { DeviceWhiteListDotMenu } from '../DeviceWhiteListDotMenu/DeviceWhiteListDotMenu';
import { formatAsDate } from 'helpers/dateUtils';
import { SortableHeader, SortingControl } from 'components/SortableHeader/SortableHeader';
import { addWhiteListFileExtension } from '../utils';

import styles from './DeviceWhiteListsPage.module.scss';

export type WhiteListsPageSortableColumnsAttributes = {
  nameSortingControl: SortingControl;
  lastChangedAtSortingControl: SortingControl;
};

const columnHelper = createColumnHelper<WhiteListDto>();

export const useDevicesWhiteListsPageColumns: (
  props: WhiteListsPageSortableColumnsAttributes,
) => ColumnDef<WhiteListDto>[] = ({ nameSortingControl, lastChangedAtSortingControl }) => {
  return useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        enableSorting: true,
        cell: ({ row }) => <div className={styles.fileName}>{addWhiteListFileExtension(row.original.name)}</div>,
        header: () => <SortableHeader sortingControl={nameSortingControl} content={'NAME'} />,
        size: 200,
        meta: {
          minSize: 200,
        },
      },
      {
        id: 'comment',
        accessorKey: 'comment',
        enableSorting: false,
        cell: ({ row }) => <div className={styles.comment}>{row.original.description}</div>,
        header: 'COMMENT',
        size: 350,
        meta: {
          minSize: 200,
        },
      },
      {
        id: 'size',
        accessorKey: 'size',
        enableSorting: false,
        cell: ({ row }) => <div>{row.original.size}</div>,
        header: 'NUMBER OF DEVICES',
        size: 150,
        meta: {
          minSize: 150,
        },
      },
      {
        id: 'lastChangedAt',
        accessorKey: 'lastChangedAt',
        enableSorting: true,
        cell: ({ row }) => <div>{formatAsDate(row.original.lastChangedAt)}</div>,
        header: () => <SortableHeader sortingControl={lastChangedAtSortingControl} content={'LAST CHANGED AT'} />,
        size: 150,
        meta: {
          minSize: 150,
        },
      },
      columnHelper.display({
        id: 'menu',
        cell: ({ row }) => <DeviceWhiteListDotMenu whiteList={row.original} />,
        header: () => null,
        size: 40,
        meta: {
          bodyCellProps: {
            className: styles.menu,
          },
        },
      }),
    ],
    [nameSortingControl, lastChangedAtSortingControl],
  );
};
