import { TextField } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { FieldArrayWithId } from 'react-hook-form';

import { getFirmwareGenerationInfo } from 'api/firmware-api';
import { AppHint } from 'components/AppHint/AppHint';
import { FirmwareContentTypeIcon } from 'features/FirmwarePage/components/FirmwareContentTypeIcon';
import { FirmwareFileActions, UploadFirmwareModel } from '../../useUploadFirmwareFilesForm/useUploadFirmwareFilesForm';
import CrossIcon from '/src/assets/icons/close-cross.svg';

import styles from './useUploadFirmwareFilesColumns.module.scss';

export type UploadFirmwareFilesColumnsAttributes = {
  actions: FirmwareFileActions;
};

export const useUploadFirmwareFilesColumns: (
  props: UploadFirmwareFilesColumnsAttributes,
) => ColumnDef<FieldArrayWithId<UploadFirmwareModel, 'files', 'id'>>[] = ({ actions: { removeFile, register } }) =>
  useMemo(
    () => [
      {
        id: 'fileName',
        accessorKey: 'fileName',
        cell: ({ row }) => (
          <>
            <FirmwareContentTypeIcon type={row.original.content.type} />
            <AppHint
              openDelay={60}
              component={<div className={styles.fileName}>{row.original.content.file.name}</div>}
              children={row.original.content.file.name}
              className={styles.fileNameContainer}
            />
          </>
        ),
        header: 'File name',
        size: 300,
        meta: {
          minSize: 300,
          bodyCellProps: {
            className: styles.fileNameCell,
          },
          headCellProps: {
            className: styles.fileNameCell,
          },
        },
      },
      {
        id: 'generation',
        accessorKey: 'generation',
        cell: ({ row }) => (
          <div className={styles.generation}>{getFirmwareGenerationInfo(row.original.generation).name}</div>
        ),
        header: 'Generation',
        size: 100,
        meta: {
          minSize: 100,
        },
      },
      {
        id: 'version',
        accessorKey: 'version',
        cell: ({ row }) => <TextField key={row.original.id} {...register(`files.${row.index}.version`)} />,
        header: 'FW version',
        size: 120,
        meta: {
          minSize: 100,
          bodyCellProps: {
            className: styles.versionCell,
          },
        },
      },
      {
        id: 'remove',
        accessorKey: 'remove',
        cell: ({ row }) => (
          <div className={styles.remove}>
            <CrossIcon
              data-testid={`table__row-${row.index}__remove-button`}
              className={styles.removeIcon}
              onClick={() => removeFile(row.index)}
            />
          </div>
        ),
        header: () => null,
        size: 24,
        meta: {
          minSize: 24,
          bodyCellProps: {
            className: styles.removeCell,
          },
          headCellProps: {
            className: styles.removeCell,
          },
        },
      },
    ],
    [removeFile, register],
  );
